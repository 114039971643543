import * as React from 'react'
import { Router } from '@reach/router'

import MultiDraw2 from '@components/MultiDraw2'
import { RoomWrapper } from '@components/Room'
import { RoomType } from '@stores/models/Room'

const MultiPage = () => {
  return (
    <Router>
      <RoomWrapper
        path="/multi2/:roomId"
        type={RoomType.MULTI}
        waitForInvited
        bw
      >
        <MultiDraw2 path="/" />
      </RoomWrapper>
    </Router>
  )
}

export default MultiPage
