/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import * as React from 'react'
import { useGesture } from 'react-use-gesture'
import useWindowSize from '@hooks/useWindowSize'
import { useStore } from '@stores/useStore'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Navigation, NavButton } from '@components/Navigation'
import { Debug } from '@components/Debug'
import Header from '@components/Header'

// Smoothing?
// https://stackoverflow.com/questions/40650306/how-to-draw-a-smooth-continuous-line-with-mouse-using-html-canvas-and-javascript

const SMALLEST_LINEWIDTH = 1
const GRID_SIZE = 50

const mapRange = (value, x1, y1, x2, y2) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2

function pixelCount(data, color = 'black') {
  let black = 0
  const checkFn = color === 'white' ? (c) => c > 127 : (c) => c < 128
  for (let i = 0; i < data.length; i += 4) {
    black += checkFn(data[i]) ? 1 : 0
  }
  return black
}

export const splitLine = (a, b, count) => {
  count = count + 1
  const d = Math.hypot(a[0] - b[0], a[1] - b[1]) / count
  const fi = Math.atan2(b[1] - a[1], b[0] - a[0])

  const points = []

  for (let i = 0; i <= count; i++) {
    points.push([a[0] + i * d * Math.cos(fi), a[1] + i * d * Math.sin(fi)])
  }

  return points
}

const getCoord = (point, offset) => {
  return [point[0] - offset[0], point[1] - offset[1]]
}

const Canvas = observer(() => {
  const room = useStore()
  const link = React.useRef(null)
  const context = React.useRef(null)
  const canvas = React.useRef(null)
  const [active, setActive] = React.useState(false)
  const [lineWidth, setLineWidth] = React.useState(SMALLEST_LINEWIDTH)
  const [offset, setOffset] = React.useState([0, 0])
  const { width: windowW, height: windowH } = useWindowSize()
  const [highlighted, setHighlighted] = React.useState([])
  const [maxSize, setMaxSize] = React.useState(20)
  const [inverted, setInverted] = React.useState(true)
  const [pLimit, setPLimit] = React.useState(60)

  const cols = React.useMemo(() => Math.floor(room.width / GRID_SIZE), [])
  const rows = React.useMemo(() => Math.floor(room.height / GRID_SIZE), [])
  const gridBlocks = React.useRef(Array(rows * cols))
  const dirtyBlocks = React.useRef(Array(rows * cols))
  const gridTotal =
    cols * GRID_SIZE * room.pixelRatio * rows * GRID_SIZE * room.pixelRatio

  const gridBlockIndex = (c, r) => r * cols + c

  const logDirtyGridBoxes = (xy, r) => {
    const x = xy[0]
    const y = xy[1]

    const top = Math.max(y - r, 0)
    const bottom = Math.min(y + r, room.height - 1)
    const left = Math.max(x - r, 0)
    const right = Math.min(x + r, room.width - 1)

    const oX = x % GRID_SIZE
    const oY = y % GRID_SIZE
    let xNo =
      right - left > GRID_SIZE ? Math.round((right - left) / GRID_SIZE) : 1
    let yNo =
      bottom - top > GRID_SIZE ? Math.round((bottom - top) / GRID_SIZE) : 1
    if (oX > GRID_SIZE * 0.5) xNo += 1
    if (oY > GRID_SIZE * 0.5) yNo += 1

    const sX = Math.floor(left / GRID_SIZE)
    const sY = Math.floor(top / GRID_SIZE)

    for (let j = 0; j < yNo; j++) {
      if (sY + j >= rows) continue
      for (let i = 0; i < xNo; i++) {
        if (sX + i >= cols) continue
        const idx = gridBlockIndex(sX + i, sY + j)
        dirtyBlocks.current[idx] = [(sX + i) * GRID_SIZE, (sY + j) * GRID_SIZE]
      }
    }
  }

  React.useEffect(() => {
    canvas.current.width = room.width * room.pixelRatio
    canvas.current.height = room.height * room.pixelRatio
    canvas.current.style.width = `${room.width}px`
    canvas.current.style.height = `${room.height}px`
    setOffset([
      (windowW - canvas.current.width / room.pixelRatio) / 2,
      (windowH - canvas.current.height / room.pixelRatio) / 2
    ])
    reset()
  }, [room.width, room.height, room.pixelRatio])

  React.useEffect(() => {
    setOffset([
      (windowW - canvas.current.width / room.pixelRatio) / 2,
      (windowH - canvas.current.height / room.pixelRatio) / 2
    ])
  }, [windowW, windowH, room.pixelRatio])

  const reset = () => {
    if (!context.current) return
    setActive(false)
    context.current.fillStyle = 'white' //user.color
    context.current.fillRect(
      0,
      0,
      room.width * room.pixelRatio,
      room.height * room.pixelRatio
    )
    if (!room.isEmpty) {
      const halfWidth = Math.round(room.width / 2)
      context.current.fillStyle = room.drawingPartner.color
      context.current.fillRect(
        room.user.initiator ? 0 : halfWidth * room.pixelRatio,
        0,
        halfWidth * room.pixelRatio,
        room.height * room.pixelRatio
      )
    }
    dirtyBlocks.current = Array(rows * cols)
    gridBlocks.current = Array(rows * cols)

    // Check for 50/50 initial filled blocks
    for (let j = 0; j < rows; j++) {
      for (let i = 0; i < cols; i++) {
        const idx = gridBlockIndex(i, j)
        dirtyBlocks.current[idx] =
          /* top left of the grid block */
          [i * GRID_SIZE, j * GRID_SIZE]
      }
    }
    update()
    context.current.beginPath()
  }

  const update = () => {
    dirtyBlocks.current.forEach((coords, i) => {
      const data = context.current.getImageData(
        coords[0] * room.pixelRatio,
        coords[1] * room.pixelRatio,
        GRID_SIZE * room.pixelRatio,
        GRID_SIZE * room.pixelRatio
      ).data
      gridBlocks.current[i] = pixelCount(data, 'black')
    })

    dirtyBlocks.current = Array(rows * cols)
    const total = gridBlocks.current.reduce((sum, b) => sum + b, 0)
    const avg = Math.round((total / gridTotal) * 100)
    setLineWidth(
      Math.max(
        1,
        mapRange(
          room.user.color === 'white' ? 100 - avg : avg,
          inverted ? pLimit : 0,
          inverted ? 0 : pLimit,
          1,
          room.width > room.height
            ? Math.round(room.height * (maxSize * 0.01))
            : Math.round(room.width * (maxSize * 0.01))
        )
      )
    )
  }

  React.useEffect(() => {
    context.current = canvas.current.getContext('2d')
    context.current.lineCap = 'round'
    context.current.lineJoin = 'round'
    context.current.miterLimit = 4
    room.setContext(context.current)
    reset()
  }, [])

  React.useEffect(() =>
    autorun(() => {
      if (room.updateCounter) {
        logDirtyGridBoxes(room.updateCounter, room.updateCounter[2])
        update()
      }
    })
  )

  React.useEffect(() => {
    return autorun(() => {
      context.current.fillStyle = room.user.color
      context.current.fillRect(
        0,
        0,
        room.width * room.pixelRatio,
        room.height * room.pixelRatio
      )
      if (!room.isEmpty) {
        const halfWidth = Math.round(room.width / 2)
        context.current.fillStyle = room.drawingPartner.color
        context.current.fillRect(
          room.user.initiator ? 0 : halfWidth * room.pixelRatio,
          0,
          halfWidth * room.pixelRatio,
          room.height * room.pixelRatio
        )
      }
      for (let j = 0; j < rows; j++) {
        for (let i = 0; i < cols; i++) {
          const idx = gridBlockIndex(i, j)
          dirtyBlocks.current[idx] =
            /* top left of the grid block */
            [i * GRID_SIZE, j * GRID_SIZE]
        }
      }
      update()
      context.current.beginPath()
    })
  }, [])

  React.useEffect(() => {
    if (!context.current) return
    context.current.strokeStyle = room.user.color
    context.current.fillStyle = room.user.color
  }, [room.user.color])

  const handleWidthChange = (size) => {
    switch (size) {
      case 0:
        setLineWidth(SMALLEST_LINEWIDTH)
        break
      case 1:
        setLineWidth(16)
        break
      case 2:
        setLineWidth(72)
        break
      default:
        setLineWidth(3)
        break
    }
  }

  const handleMouseMove = (e) => {
    if (!active) return
    context.current.fillStyle = room.user.color
    drawOnCanvas(e)
  }

  const handleMouseDown = (e) => {
    setActive(true)
    context.current.fillStyle = room.user.color
  }

  const handleMouseUp = () => {
    setActive(false)
  }

  const drawOnCanvas = (e) => {
    if (e.first || e.last) return

    const ctx = context.current
    const distance = Math.hypot(
      e.xy[0] - e.previous[0],
      e.xy[1] - e.previous[1]
    )
    const step = Math.max(1, distance / (lineWidth / 5))

    const start = getCoord(e.previous, offset)
    const end = getCoord(e.xy, offset)

    const points = splitLine(end, start, step)
    room.sendMessage({
      type: 'line',
      start,
      end,
      size: lineWidth,
      color: room.user.color,
      step
    })
    points.forEach((p) => {
      ctx.beginPath()
      ctx.arc(
        p[0] * room.pixelRatio,
        p[1] * room.pixelRatio,
        lineWidth,
        0,
        2 * Math.PI
      )
      ctx.fill()
      ctx.closePath()
    })
    logDirtyGridBoxes(end, lineWidth)
    update()
  }

  const bind = useGesture(
    {
      onDrag: (state) => handleMouseMove(state),
      onDragStart: (state) => handleMouseDown(state),
      onDragEnd: (state) => handleMouseUp(state)
    },
    {
      eventOptions: {
        pointer: false
      }
    }
  )

  const gridCells = []
  if (room.debug) {
    for (let j = 0; j < rows; j++) {
      for (let i = 0; i < cols; i++) {
        const idx = gridBlockIndex(i, j)
        gridCells.push(
          <div
            key={`cell-${i}-${j}`}
            sx={{
              color: 'red',
              opacity: 0.5,
              boxShadow: '0 0 0 1px rgba(0, 0, 255, 0.5)',
              width: GRID_SIZE,
              height: GRID_SIZE,
              bg: highlighted.includes(idx)
                ? 'rgba(255,255,0,0.25)'
                : 'transparent'
            }}
          >
            {gridBlocks.current[gridBlockIndex(i, j)]}
            <br />
            {Math.round(
              ((gridBlocks.current[gridBlockIndex(i, j)] || 1) /
                (GRID_SIZE * GRID_SIZE * room.pixelRatio * room.pixelRatio)) *
                100
            )}
            %
          </div>
        )
      }
    }
  }

  return (
    <React.Fragment>
      <Debug>
        <button onClick={() => reset()}>
          <span role="img" aria-label="reset">
            🔄
          </span>
        </button>
        <label>
          <span>Invert</span>
          <input
            type="checkbox"
            checked={inverted}
            onChange={(e) => {
              setInverted((v) => !v)
            }}
          />
        </label>
        <button onClick={() => handleWidthChange(0)}>S</button>
        <button onClick={() => handleWidthChange(1)}>M</button>
        <button onClick={() => handleWidthChange(2)}>L</button>
        <label>
          <span>Max size</span>
          <input
            type="range"
            min={0.5}
            max={20}
            value={maxSize}
            step="0.5"
            onChange={(e) => {
              setMaxSize(e.target.value)
            }}
          />
          <span className="value">{maxSize}%</span>
        </label>
        <br />
        <label>
          <span>%-limit</span>
          <input
            type="range"
            min={50}
            max={100}
            value={pLimit}
            step="1"
            onChange={(e) => {
              setPLimit(e.target.value)
            }}
          />
          <span className="value">{pLimit}</span>
        </label>
      </Debug>
      <a href="" download ref={link} style={{ visibility: 'hidden' }} />
      <canvas ref={canvas} {...bind()} />
      {room.debug && (
        <div
          sx={{
            width: room.width,
            height: room.height,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none'
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              fontSize: 1
            }}
          >
            {gridCells}
          </div>
        </div>
      )}
      <Navigation show={!room.isEmpty && !active}>
        <NavButton
          onClick={() => {
            link.current.setAttribute('download', 'ChromaDualAlt-drawing.png')
            const img = canvas.current
              .toDataURL('image/png')
              .replace('image/png', 'image/octet-stream')
            link.current.setAttribute('href', img)
            link.current.click()
          }}
        >
          Save
        </NavButton>
      </Navigation>
      <Header show={!active} />
      {room.drawingPartner && room.drawingPartner.disconnecting && (
        <Text
          sx={{
            position: 'absolute',
            top: '0.3em',
            left: '50%',
            textAlign: 'center',
            color: 'white',
            mixBlendMode: 'difference',
            transform: 'translateX(-50%)'
          }}
        >
          <span sx={{ textTransform: 'capitalize' }}>
            {room.drawingPartner.humanColor}
          </span>
          {` left`}
        </Text>
      )}
    </React.Fragment>
  )
})

export default Canvas
